// "Dynamically" load `support/modern` or `support/legacy`
import { logger } from '@/inc/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export default function (ctx: any): void {
  logger.trace('vapperClient', ctx)

  const initApp = (): void => {
    logger.trace('initApp')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const domReady = (): Promise<any> =>
    new Promise(resolve => {
      if (
        document.readyState === 'complete' ||
        document.readyState !== 'loading'
      ) {
        resolve(undefined)
      } else {
        document.addEventListener('DOMContentLoaded', resolve)
      }
    })

  Promise.all([domReady()]).then(initApp)
}
