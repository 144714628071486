import { render, staticRenderFns } from "./Legals.vue?vue&type=template&id=2ddc2f62"
import script from "./Legals.vue?vue&type=script&lang=ts"
export * from "./Legals.vue?vue&type=script&lang=ts"
import style0 from "./Legals.vue?vue&type=style&index=0&id=2ddc2f62&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports